$backgroundColor: #ffffff;
$ohlalaBlue: #194157;
$ohlalaRed: #ca3d2b;
$ohlalaRedDarken: #a83223;
$ohlalaOrange: #db8129;
$ohlalaOrangeDarken: #b36a20;
$ohlalaOrangeTransparent: #db82295f;
$bodyColor: #000000;

@use './includes/header.scss';
@use './includes/footer.scss';
@use './includes/legal-notice-modal.scss';
@use './includes/cookies-message.scss';

//@use './includes/waiting-list.scss';
//@use './includes/appraisals.scss';
//@use './includes/subscribe-form.scss';
//@use './includes/faq.scss';
//@use './includes/teachers.scss';
//@use './includes/school-products.scss';
//@use './includes/bonus.scss';
//@use './index.scss';
//@use './school.scss';
//@use './frenchoteque-test.scss';
//@use './frenchoteque.scss';
//@use './coffee.scss';
//@use './course.scss';
//@use './error.scss';
//@use './group.scss';
//@use './about.scss';
//@use './contact.scss';
//@use './podcastplus.scss';
//@use './newsletter-form.scss';
//@use './evaluation.scss';
//@use './affiliation.scss';
//use './bundle.scss';


@font-face {
  font-family: 'font1';
  font-display: swap;
  src: url(../fonts/fba574ef4dc01d71937608b6f764e2e7.woff2);
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'font2';
  font-display: swap;
  src: url(../fonts/e5d4e13df62137d60514583c043c9e4b.woff2);
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'font1';
  font-display: swap;
  src: url(../fonts/fba574ef4dc01d71937608b6f764e2e7.woff2);
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'font1';
  font-display: swap;
  src: url(../fonts/fba574ef4dc01d71937608b6f764e2e7.woff2);
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'font1';
  font-display: swap;
  src: url(../fonts/fba574ef4dc01d71937608b6f764e2e7.woff2);
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'font1';
  font-display: swap;
  src: url(../fonts/fba574ef4dc01d71937608b6f764e2e7.woff2);
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'font1';
  font-display: swap;
  src: url(../fonts/fba574ef4dc01d71937608b6f764e2e7.woff2);
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'font1';
  font-display: swap;
  src: url(../fonts/fba574ef4dc01d71937608b6f764e2e7.woff2);
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'font1';
  font-display: swap;
  src: url(../fonts/fba574ef4dc01d71937608b6f764e2e7.woff2);
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'font1';
  font-display: swap;
  src: url(../fonts/fba574ef4dc01d71937608b6f764e2e7.woff2);
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'font1';
  font-display: swap;
  src: url(../fonts/fba574ef4dc01d71937608b6f764e2e7.woff2);
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'font1';
  font-display: swap;
  src: url(../fonts/fba574ef4dc01d71937608b6f764e2e7.woff2);
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'font1';
  font-display: swap;
  src: url(../fonts/fba574ef4dc01d71937608b6f764e2e7.woff2);
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'font1';
  font-display: swap;
  src: url(../fonts/fba574ef4dc01d71937608b6f764e2e7.woff2);
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'font1';
  font-display: swap;
  src: url(../fonts/fba574ef4dc01d71937608b6f764e2e7.woff2);
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'font1';
  font-display: swap;
  src: url(../fonts/fba574ef4dc01d71937608b6f764e2e7.woff2);
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'font1';
  font-display: swap;
  src: url(../fonts/fba574ef4dc01d71937608b6f764e2e7.woff2);
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'font1';
  font-display: swap;
  src: url(../fonts/fba574ef4dc01d71937608b6f764e2e7.woff2);
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'font1';
  font-display: swap;
  src: url(../fonts/fba574ef4dc01d71937608b6f764e2e7.woff2);
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'font1';
  font-display: swap;
  src: url(../fonts/fba574ef4dc01d71937608b6f764e2e7.woff2);
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'font2';
  font-display: swap;
  src: url(../fonts/e5d4e13df62137d60514583c043c9e4b.woff2);
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'font2';
  font-display: swap;
  src: url(../fonts/e5d4e13df62137d60514583c043c9e4b.woff2);
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'font2';
  font-display: swap;
  src: url(../fonts/e5d4e13df62137d60514583c043c9e4b.woff2);
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'font2';
  font-display: swap;
  src: url(../fonts/e5d4e13df62137d60514583c043c9e4b.woff2);
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'font2';
  font-display: swap;
  src: url(../fonts/e5d4e13df62137d60514583c043c9e4b.woff2);
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'font2';
  font-display: swap;
  src: url(../fonts/e5d4e13df62137d60514583c043c9e4b.woff2);
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'font2';
  font-display: swap;
  src: url(../fonts/e5d4e13df62137d60514583c043c9e4b.woff2);
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'font2';
  font-display: swap;
  src: url(../fonts/e5d4e13df62137d60514583c043c9e4b.woff2);
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'font2';
  font-display: swap;
  src: url(../fonts/e5d4e13df62137d60514583c043c9e4b.woff2);
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'font2';
  font-display: swap;
  src: url(../fonts/e5d4e13df62137d60514583c043c9e4b.woff2);
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'font2';
  font-display: swap;
  src: url(../fonts/e5d4e13df62137d60514583c043c9e4b.woff2);
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'font2';
  font-display: swap;
  src: url(../fonts/e5d4e13df62137d60514583c043c9e4b.woff2);
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'font2';
  font-display: swap;
  src: url(../fonts/e5d4e13df62137d60514583c043c9e4b.woff2);
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'font2';
  font-display: swap;
  src: url(../fonts/e5d4e13df62137d60514583c043c9e4b.woff2);
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'font2';
  font-display: swap;
  src: url(../fonts/e5d4e13df62137d60514583c043c9e4b.woff2);
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'font2';
  font-display: swap;
  src: url(../fonts/e5d4e13df62137d60514583c043c9e4b.woff2);
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'font2';
  font-display: swap;
  src: url(../fonts/e5d4e13df62137d60514583c043c9e4b.woff2);
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'font2';
  font-display: swap;
  src: url(../fonts/e5d4e13df62137d60514583c043c9e4b.woff2);
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat', sans-serif;
  font-display: swap;
  src: url(../fonts/881b8da5ad9b82b143ab37dcdf069c4c.woff2);
  font-weight: 400;
  font-style: normal;
}

html {
  overflow-x: hidden;
}

body {
  background: $backgroundColor;
  color: $bodyColor;
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  padding: 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: auto;
  overflow-x: hidden;
}

input:focus,
textarea:focus {
  outline: none !important;
  border: 2px solid $ohlalaBlue;
  box-shadow: 0 0 8px $ohlalaBlue;
}

input,
textarea {
  border: 1px solid #d1d1d1
}

.main {
  padding-top: 120px;
}

.container {
  max-width: 1140px;
  margin: 0 auto;
  height: 100%;
  position: relative;
}

.section-blue {
  background-color: $ohlalaBlue;
  color: #fff;
}

.section-red {
  background-color: $ohlalaRed;
  color: #fff;
}

.cta-btn-box {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 40px;

  .cta-button {
    border: none;
    border-radius: 0.3125rem;
    background-color: $ohlalaOrange;
    font-family: 'font1';
    color: #ffffff;
    font-size: 1.8rem;
    padding: 8px 20px;
    cursor: pointer;
    text-decoration: none;
    text-align: center;

    &:hover {
      background-color: $ohlalaOrangeDarken;
      text-decoration: none;
    }

    &:disabled {
      background-color: $ohlalaOrangeTransparent;
    }
  }

  .cta-note {
    text-align: center;
    margin-top: 5px;
    font-style: italic;
    font-size: 1rem;
  }
}

.icon {
  width: 40px;
  min-width: 40px;
  height: 40px;
  display: inline-block;
  background-size: cover;
}

.icon-explode {
  background-image: url(../images/icons/explode.png);
}

.icon-shuut {
  background-image: url(../images/icons/shuut.png);
}

.icon-sad {
  background-image: url(../images/icons/sad.png);
}

.icon-monkey {
  background-image: url(../images/icons/monkey.png);
}

.icon-surprised {
  background-image: url(../images/icons/surprised.png);
}

.icon-thumb {
  background-image: url(../images/icons/thumb.png);
}

.icon-brain {
  background-image: url(../images/icons/brain.png);
}

.icon-glasses {
  background-image: url(../images/icons/glasses.png);
}

.icon-scream {
  background-image: url(../images/icons/scream.png);
}

.icon-pen {
  background-image: url(../images/icons/pen.png);
}

.icon-flag {
  background-image: url(../images/icons/flag.png);
}

.icon-love {
  background-image: url(../images/icons/love.png);
}

.icon-sad2 {
  background-image: url(../images/icons/sad2.png);
}

.icon-scared {
  background-image: url(../images/icons/scared.png);
}

.icon-scared2 {
  background-image: url(../images/icons/scared2.png);
}

.icon-pointing {
  background-image: url(../images/icons/pointing.png);
}

.icon-check {
  background-image: url(../images/icons/check.png);
  background-size: cover;
}

.icon-wrong {
  background-image: url(../images/icons/wrong.png);
  background-size: cover;
}

.icon-wrong-white {
  background-image: url(../images/icons/wrongwhite.png);
  background-size: cover;
}

.icon-trophy {
  background-image: url(../images/icons/trophy.png);
}

.icon-rocket {
  background-image: url(../images/icons/rocket.png);
}

.icon-eye-stars {
  background-image: url(../images/icons/eye_stars.png);
}

.icon-check-black {
  background-image: url(../images/icons/checkblack.png);
}

.icon-laptop {
  background-image: url(../images/icons/laptop.png);
}

.icon-lock {
  background-image: url(../images/icons/lock.png);
}

.icon-paper-pen {
  background-image: url(../images/icons/paperpen.png);
}

.icon-paper-pen-check {
  background-image: url(../images/icons/paperpencheck.png);
}

.icon-persons {
  background-image: url(../images/icons/persons.png);
}

.icon-plus-black {
  background-image: url(../images/icons/plusblack.png);
}

.icon-numberbox1 {
  background-image: url(../images/icons/numberbox1.png);
}

.icon-numberbox2 {
  background-image: url(../images/icons/numberbox2.png);
}

.icon-numberbox3 {
  background-image: url(../images/icons/numberbox3.png);
}

.icon-numberbox4 {
  background-image: url(../images/icons/numberbox4.png);
}

.icon-heart {
  background-image: url(../images/icons/heart.png);
}

.icon-gift {
  background-image: url(../images/icons/gift.png);
}

.icon-exhaling {
  background-image: url(../images/icons/exhaling.png);
}

.icon-rolling-eyes {
  background-image: url(../images/icons/rolling_eyes.png);
}

.icon-sweat {
  background-image: url(../images/icons/sweat.png);
}

.icon-wink {
  background-image: url(../images/icons/wink.png);
}

.icon-target {
  background-image: url(../images/icons/target.png);
}






.modal {
  display: none;
}

#newsletterModal {
  display: none;
}

.newsletter-modal {
  padding: 20px;

  .quote {
    font-size: 1.8rem;
    text-align: center;
    font-weight: bold;
  }

  .catch-question {
    font-size: 1.6rem;
    text-align: center;
    margin-top: 20px;

    .highlight {
      font-family: 'font2';
      font-size: 2.8rem;
      color: $ohlalaBlue;
      margin: 0 5px;

    }
  }

  .newsletter-box {
    margin-top: 20px;
    margin-bottom: 0;
  }
}

.frenchoteque-test-modal {

  .frenchoteque-test-modal-content {}
}

.highlight {
  text-align: right;
  font-family: 'font2';
  font-size: 4rem;
}

input {
  width: 100%;
  font-size: 1.3rem;
  padding: 6px 6px;

  &::placeholder {
    color: rgb(192, 192, 192);
  }

}

.modalContent {
  max-width: 400px !important;
}

.modal {
  display: none; // Cachée par défaut
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;

  .modal-background {
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
  }

}

.promo-modal {
  padding: 20px;
  position: relative;
  background-color: #fff;
  max-width: 500px !important;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border-radius: 20px;


  @media only screen and (max-width: 550px) {
    width: calc(100% - 50px);
    left: 0;
    transform: translate(0, -50%);

  }

  .promo-modal-close {
    position: absolute;
    top: 8px;
    right: 8px;
    background: none;
    border: none;
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    cursor: pointer;
    padding: 4px;
    transition: color 0.2s;
    text-decoration: none;

    &:hover {
      color: #d9534f; // Rouge pour indiquer la fermeture
    }

    &:focus {
      outline: 2px solid #0056b3; // Indicateur d'accessibilité
      outline-offset: 2px;
    }
  }

  .bubble {
    background-color: $ohlalaRed;
    color: #fff;
    font-family: 'font1';
    font-size: 2rem;
    text-align: center;
    padding: 10px;
    position: absolute;
    border-radius: 20px;
    top: -45px;
    left: 145px;
    z-index: 9000;
    width: calc(250px - 20px);
    height: calc(75px - 20px);
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 3px 11px rgba(0, 0, 0, 0.4);

    @media only screen and (max-width: 550px) {
      position: relative !important;
      top: 0 !important;
      left: 0 !important;
      width: calc(100% - 60px) !important;
      margin: 0 20px;

      &::after {
        display: none;
      }
    }

    &::after {
      content: '';
      position: absolute;
      bottom: -5px;
      left: 50%;
      transform: translateX(-50%) rotate(45deg);
      width: 50px;
      height: 50px;
      background: inherit;
      border-radius: 3px;
      z-index: -1;
    }
  }

  .split {
    display: flex;
    padding-top: 40px;

    .promo-value {
      display: flex;
      justify-content: center;
      padding: 10px;
      font-size: 3.4rem;
      font-weight: bold;
      margin-right: 20px;
      min-width: 130px;
    }

    .promo-description {
      font-size: 1.4rem;
    }

    @media only screen and (max-width: 550px) {
      flex-direction: column;
      text-align: center;
      padding-top: 0px !important;
    }
  }

  .promo-code {
    font-weight: bold;
    text-decoration: underline;
    font-size: 2rem;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .cta-btn-box {
    margin-bottom: 0;
  }

}



@media only screen and (max-width: 991px) {

  .main {
    padding-top: 60px;
  }

  .icon {
    width: 30px;
    min-width: 30px;
    height: 30px;
    display: inline-block;
    background-size: cover;
  }

  .container {
    max-width: 720px;
    padding: 0 20px;
  }
}

@media only screen and (max-width: 1200px) and (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
