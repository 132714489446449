@font-face {
  font-family: "Font Awesome 6 Free";
  font-style: normal;
  font-weight: 900;
  src: url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/webfonts/fa-solid-900.woff2") format("woff2"),
    url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/webfonts/fa-solid-900.woff") format("woff");
}

.fa-solid {
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  display: inline-block;
  font-style: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fa-chevron-left:before {
  content: "\f053";
}

.fa-play-circle:before {
  content: "\f144";
}

.fa-square-check:before {
  content: "\f14a";
}

.fa-circle-check:before {
  content: "\f058";
}

.fa-bars:before {
  content: "\f0c9";
}

.fa-times:before {
  content: "\f00d";
}
