$backgroundColor: #ffffff;
$ohlalaBlue: #194157;
$ohlalaRed: #ca3d2b;
$ohlalaRedDarken: #a83223;
$ohlalaOrange: #db8129;
$ohlalaOrangeDarken: #b36a20;
$ohlalaOrangeTransparent: #db82295f;
$bodyColor: #000000;

.footer {
  background-color: $ohlalaBlue;
  height: 400px;

  .footer-content {
    display: flex;
    color: #ffffff;
    font-size: 1.2rem;
    width: 100%;
    padding-top: 40px;

    a {
      color: #ffffff;
      text-decoration: none;
    }

    a:hover {
      text-decoration: underline;
    }

    .left {
      width: 67%;
      display: flex;

      .contact {
        width: 60%;
      }

      .quick-links {
        ul {
          list-style-type: none;
          margin: 0;
          padding: 0;
        }
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 33%;

      .social-links {
        display: flex;
        justify-content: space-around;
        width: 17.5rem;
        margin-top: 1.25rem;

        a {

          img {
            height: 2.5rem;
          }
        }
      }
    }

    .title {
      font-size: 1.6rem;
      font-weight: bold;
    }
  }

  .copyright {
    text-align: center;
    position: absolute;
    bottom: 20px;
    width: 100%;
    color: #ffffff;

    a {
      color: white;
      text-decoration: none;
    }

    a:hover {
      text-decoration: underline;
    }
  }
}

@media only screen and (max-width: 991px) {
  .footer {

    height: auto;
    padding: 30px 0 60px 0;

    .footer-content {
      display: flex;
      flex-direction: column;
      padding-top: 0;


      .left {
        width: 100%;
        display: flex;
        flex-direction: column;

        .contact {
          width: 100%;
        }
      }

      .right {
        align-items: flex-start;
        width: 100%;
      }

      .title {
        margin-bottom: 10px;
        margin-top: 10px;
      }
    }

    .copyright {
      position: static;
      margin-top: 20px;
    }
  }
}
