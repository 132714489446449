.cookies-message {
  position: fixed;
  bottom: 0;
  background-color: #fff;
  padding: 20px;
  -webkit-box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.45);
  box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.45);
  width: calc(100vw - 40px);
  display: none;
  opacity: 0;
  transition: opacity 1s ease;

  .cookies-message-title {
    font-size: 1rem;
    font-weight: bold;
  }

  .cookies-message-content {
    display: flex;
    width: 100%;

    .cookies-message-body {
      font-size: 0.8rem;
      margin-right: 20px;
    }

    .cta-btn-box {
      width: 15%;
      margin-bottom: 0;
      margin-left: auto;

      .cta-button {
        font-size: 1.2rem;
      }
    }
  }
}


@media only screen and (max-width: 768px) {
  .cookies-message {

    .cookies-message-content {
      flex-direction: column;
      align-items: center;

      .cookies-message-body {
        width: 100%;
        margin-right: 0;
      }

      .cta-btn-box {
        margin-top: 10px;
        width: 15%;
        margin-bottom: 0;
        margin-left: 0;


        .cta-button {
          font-size: 1.2rem;
        }
      }
    }
  }
}
