.legal-notice-modal {
  height: 100%;

  .legal-notice-modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid #dee2e6;
    font-family: 'Montserrat', serif !important;
    font-weight: bold;
    font-size: 1.4rem;
  }

  .legal-notice-modal-content {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
    overflow-y: auto;
    height: calc(100% - 93px);
  }

  .legal-notice-modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 1rem;
    border-top: 1px solid #dee2e6;

    button {
      color: #fff;
      background-color: #6c757d;
      border-color: #6c757d;
      padding: 5px 15px;
      cursor: pointer;

      &:hover {
        background-color: #4e555c;
      }
    }

   
  }

}
