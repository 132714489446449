.header {
  background-color: #ffffff;
  height: 120px;
  padding: 0 20px;
  position: fixed;
  width: calc(100vw - 40px);
  z-index: 9746;

  .navbar {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    .logo {
      margin-right: 20px;
      width: 100px;
      height: 100px;
      background-image: url('https://cdn.ohlalafrenchcourse.com/site/website/logo_nav.webp');
      background-repeat: no-repeat;
      background-size: cover;
    }

    .name {
      display: none;
    }

    .horizontal-menu {
      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
      }

      li {
        display: inline;
      }

      .menu-item {
        text-transform: uppercase;
        font-family: 'font1';
        padding: 0 5px;
        margin: 0 5px;
        font-size: 1.4rem;
        text-decoration: none;
        color: #6c6c6c;
      }

      .menu-item:hover {
        text-decoration: underline;
        color: #000000;
      }

      .menu-item.active {
        text-decoration: underline;
        color: #000000;
      }
    }

    .language-selector {
      display: flex;
      flex-direction: column;
      /* Espacement entre les langues */
      align-items: center;
      margin-right: 20px;

      @media only screen and (max-width: 991px) {
        margin-top: 40px;
        margin-right: 0;
        gap: 20px;
        flex-direction: row;
        justify-content: center;
      }

      .lang-link {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: #333;
        font-weight: bold;
        font-size: 14px;
      }

      .lang-link span {
        margin-left: 5px;
      }

      .lang-link:hover {
        color: #194157;
      }

      .lang-link.active {
        pointer-events: none;
        color: #aaa;
        font-weight: bold;
      }
    }

    .navbar-right {
      margin-left: auto;
      display: flex;
      align-items: center;
      margin-right: 20px;





      .ohlala-french-school-btn {
        background-color: #ca3d2b;
        border: none;
        height: 40px;
        font-family: 'font1';
        color: white;
        font-size: 1.4rem; //font-size:calc(100% + 1.5vw);
        text-align: center;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 10px;
        border-radius: 0.3125rem;

        &:hover {
          background-color: #a83223;
        }
      }


      .connect-btn {
        border: 1px solid #000000;
        background-color: transparent;
        color: #000000;
        font-size: 1.4rem;
        font-family: 'font1';
        height: 40px;
        margin-left: 10px;
        text-decoration: none;
        line-height: 40px;
        padding: 0 10px;
        border-radius: 0.3125rem;

        &:hover {
          text-decoration: underline;
        }
      }

    }

    .navbar-right-mobile {
      display: none;
    }
  }
}

@media only screen and (max-width: 1059px) {
  .header {
    .navbar {
      .horizontal-menu {
        .menu-item {
          font-size: 1rem;
          margin: 0 0px;
        }
      }

      .navbar-right {

        .ohlala-french-school-btn {
          font-size: 1.4rem;
        }

        .connect-btn {
          font-size: 1rem;
        }

      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .header {
    height: 60px;
    padding: 0 10px;
    width: calc(100vw - 20px);

    .navbar {

      .logo {
        width: 55px;
        height: 55px;
        margin-right: 5px;
      }

      .name {
        display: block;
        font-family: 'font1';
        font-size: 1.2rem;
      }

      .horizontal-menu {
        display: none;
      }

      .navbar-right {
        display: none;
      }

      .navbar-right-mobile {
        display: block;
        margin-right: 10px;

        a {
          color: #000;
          font-size: 1.6rem;
        }

        button {
          color: #000;
          font-size: 1.6rem;
          border: none;
          background-color: transparent;
          padding: 0;
        }

        .close-menu-btn {
          display: none;
        }

        .mobile-menu {
          width: 100vw;
          height: calc(100vh - 60px);

          position: absolute;
          top: 60px;
          right: 0;
          z-index: 1934;
          display: none;
          opacity: 1;


          .menu-overlay {
            background-color: rgba(0, 0, 0, 0.6);
            width: 100%;
            height: 100%;
            position: absolute;
          }

          .menu-container {
            width: 100%;
            height: 100%;
            position: relative;

            .menu {
              position: absolute;
              width: calc(85% - 40px);
              height: 100%;
              background-color: #fff;
              top: 0;
              right: 0;
              padding: 20px;
              border-top: 1px solid #f5f5f5;

              ul {
                list-style-type: none;
                margin: 0;
                padding: 0;
              }

              li {
                padding: 8px 0;
              }


              a {
                text-decoration: none;
                font-size: 1.2rem;
              }

              .buttons-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 20px;

                .ohlala-french-school-btn {
                  font-size: 1.4rem;
                  width: 100%;
                }

                .connect-btn {
                  margin-top: 20px;
                  text-align: center;
                  width: 100%;
                }

              }
            }

            .collapse {
              animation-name: slideIn;
              animation-duration: 0.5s;
            }

            .closing {
              animation-name: slideOut;
              animation-duration: 0.5s;
            }

            @keyframes slideIn {
              0% {
                right: -85%;
              }

              100% {
                right: 0;
              }
            }

            @keyframes slideOut {
              0% {
                right: 0;
              }

              100% {
                right: -85%;
              }
            }
          }
        }

        .show {
          animation-name: fadeIn;
          animation-duration: 0.5s;
        }

        .exit {
          animation-name: fadeOut;
          animation-duration: 0.5s;
        }

        @keyframes fadeIn {
          0% {
            opacity: 0.1;
          }

          100% {
            opacity: 1;
          }
        }

        @keyframes fadeOut {
          0% {
            opacity: 1;
          }

          100% {
            opacity: 0.1;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1179px) and (min-width: 1060px) {
  .header {
    .navbar {
      .horizontal-menu {
        .menu-item {
          font-size: 1.2rem;
          margin: 0 0px;
        }
      }

      .navbar-right {

        .ohlala-french-school-btn {
          font-size: 1.4rem;
        }

        .connect-btn {
          font-size: 1.2rem;
        }

      }
    }
  }
}
